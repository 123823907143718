/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Navigation Hamburger
        (function() {
          var toggle = document.querySelectorAll('.navigation-main__toggle');
          var navigation = document.querySelectorAll('.navigation-main__container');

          toggle[0].addEventListener('click', function(e) {
            e.preventDefault();

            navigation[0].classList.toggle('navigation-main__container--is-visible');
          });
        }(window));

		// submenu´ logic for hamburger menu
		if($('.navigation-main__toggle:visible').length > 0) {
			$(".navigation-main .menu-item .sub-menu").each(function(idx,el){
				console.log($(this).css("display"));
				if($(this).css("display") !== "none") {
					$(this).closest(".menu-item").removeClass("submenu_closed").addClass("submenu_open");
				}
				else {
					$(this).closest(".menu-item").removeClass("submenu_open").addClass("submenu_closed");
				}
			});
			
			$(".navigation-main .submenu_button").click(function(e){
				$(this).closest(".menu-item").children(".sub-menu").slideToggle("slow",function(e){
					if($(this).is(":visible")) {
						$(this).closest(".menu-item").removeClass("submenu_closed").addClass("submenu_open");
					}
					else {
						$(this).closest(".menu-item").removeClass("submenu_open").addClass("submenu_closed");
					}
				});
			});
		} 
		 
		// move text down if multiline menus visible
		var menuSpacer= function() {
			
			function getAllHeight(jqEl)
			{
				var allHeight = 0;
				jqEl.each(function() {
					if ($(this).css("zIndex") === "200") {
						return; //ignore vertical menus
					}
					allHeight += $(this).height();
				});		
				return allHeight;
			}

			function resetMenuMargin()
			{
				if(parseInt($(".navigation-main").css("margin-bottom")) > defMenuMargin)
				{
					$(".navigation-main").stop();
					$(".navigation-main").animate({"margin-bottom" : defMenuMargin + "px"},250,"swing");
				}
				
			}
			
			function marginResize()
			{
				var submen=	$('.navigation-main ul.navigation-main__container .menu-item .sub-menu:visible');
				if(submen.length === 0 )
				{
					resetMenuMargin();
					return;
				}
				
				var h=getAllHeight(submen);

				if(h && h > defMenuMargin * 1.3)
				{
					$(".navigation-main").stop();
					$(".navigation-main").animate({"margin-bottom" : h + "px"},300,"swing");
				}
				else {
					resetMenuMargin();
				}
				
			}
			
			function getMaxHeight(jqEl)
			{
				var maxHeight = -1;
				jqEl.each(function() {
					if ($(this).height() > maxHeight) {
						maxHeight = $(this).height();
					}
				});		
				return maxHeight;
			}
			
			if($("button.navigation-main__toggle:visible").length > 0) {
				return;
			}
		    defMenuMargin=parseInt($(".navigation-main").css("margin-bottom"));
			marginResize();

			$('.navigation-main ul.navigation-main__container .menu-item').hover(marginResize,marginResize);

		};	
		menuSpacer();
	
        // Newsletter form
        var findRadioSelection = function(fieldName) {
            var test = document.getElementsByName(fieldName);
            var sizes = test.length;

            for (i=0; i < sizes; ++i) {
              if (test[i].checked===true) {
                return test[i].value;
              }
            }
        };

        var newsletter_form = document.querySelectorAll('.newsletter__form');

        newsletter_form[0].addEventListener('submit', function(e) {
          e.preventDefault();

          var method = newsletter_form[0].getAttribute('method');
          var url = newsletter_form[0].getAttribute('action');

          var email = document.querySelectorAll('#email')[0].value;
          var language = findRadioSelection('language');
          var name = document.querySelectorAll('#name')[0].value;

          jQuery.ajax({
            method: method,
            url: url,
            data: {
              email: email,
              language: language,
              name: name,
            }
          })
          .done(function() {
            /* hide signup newsletter_form, show success message instead */
            var newsletterElement = document.querySelectorAll('.newsletter');
            newsletterElement[0].classList.add('hidden');
            newsletterElement[1].classList.remove('hidden');

          });
        });

        ///////
        // Accordion
        ///////
		$(".accordion_trigger[aria-expanded=false]").next().hide();
		$(".accordion_trigger").click(function(e){
			var me=$(this);
			$(this).next().slideToggle('slow',function(){
				if($(this).is(":visible")) {
					me.attr("aria-expanded","true"); }
				else {
					me.attr("aria-expanded","false"); }
			});
			return false;
		});
		
		
        ///////
        // Accordion Widget
        ///////
		$('.sidebar span.widget_accordion + section.widget > :first-child').click(function(e){
			var widget=$(this).closest(".widget");
			var accordion_widget=widget.prev("span.widget_accordion");

			if(accordion_widget.hasClass("closed"))
			{
				widget.children().slice(1).slideDown("slow",function(){
					accordion_widget.removeClass("closed").addClass("open");
				});
			}
			else
			{
				widget.children().slice(1).slideUp("slow",function(){
					accordion_widget.removeClass("open").addClass("closed");
				});
			}
		});
		
		
        ///////
        // Donation form
        ///////
        var donation_form = document.querySelectorAll('.donation__form');
		
		if(donation_form.length > 0) {
			donation_form[0].addEventListener('submit', function(e) {
			  e.preventDefault();

			  var method = donation_form[0].getAttribute('method');
			  var url = donation_form[0].getAttribute('action');

			  var amount = document.querySelectorAll('#amount')[0].value;
			  var donation_type = findRadioSelection('donation_type');
			  var owner = document.querySelectorAll('#owner')[0].value;
			  var iban = document.querySelectorAll('#iban')[0].value.replace(/ /g,'');
			  var email = document.querySelectorAll('#email')[0].value;

			  // validate iban
			  if (!IBAN.isValid(iban)) {
				return alert('IBAN is not valid!');
			  }

			  jQuery.ajax({
				method: method,
				url: url,
				data: {
				  amount: amount,
				  donation_type: donation_type,
				  owner: owner,
				  iban: iban,
				  email: email,
				}
			  })
			  .done(function() {
				/* hide signup donation_form, show success message instead */
				var donationElement = document.querySelectorAll('.donation');
				donationElement[0].classList.add('hidden');
				donationElement[1].classList.remove('hidden');
			  });
		});	}		
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
